var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"edit-client-sidebar","visible":_vm.isEditProductSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":"","width":_vm.$store.getters['app/currentBreakPoint'] === 'xs' ? '100%' : '420px'},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-edit-product-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(((_vm.t('Edit Kassa')) + " #" + _vm.id))+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{directives:[{name:"show",rawName:"v-show",value:(!_vm.onSubmitProcess.open),expression:"!onSubmitProcess.open"}],ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('validation-provider',{attrs:{"name":_vm.t('Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.t('Name'),"label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","autofocus":"","state":_vm.getValidationState(validationContext),"trim":"","placeholder":"Name"},model:{value:(_vm.kassaData.name),callback:function ($$v) {_vm.$set(_vm.kassaData, "name", $$v)},expression:"kassaData.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.t('Responsible'),"rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.t('Responsible'),"label-for":"responsible","state":_vm.getValidationState(validationContext)}},[_c('v-select',{ref:"refRespOpt",attrs:{"state":_vm.getValidationState(validationContext),"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.users,"label":"name","reduce":function (val) { return val.id; },"input-id":"responsible","clearable":false},scopedSlots:_vm._u([{key:"list-header",fn:function(){return [(_vm.users.length === 0)?_c('li',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Загрузка...")])],1):_vm._e()]},proxy:true},{key:"selected-option",fn:function(ref){
var name = ref.name;
var active = ref.active;
return [_c('b-avatar',{attrs:{"size":"26","text":_vm.avatarText(name),"variant":("light-" + (_vm.resolveUserAvatarVariant(active)))}}),_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(name)+" ")])]}},{key:"option",fn:function(ref){
var name = ref.name;
var active = ref.active;
return [_c('b-avatar',{attrs:{"size":"26","text":_vm.avatarText(name),"variant":("light-" + (_vm.resolveUserAvatarVariant(active)))}}),_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(name)+" ")])]}}],null,true),model:{value:(_vm.kassaData.resp_id),callback:function ($$v) {_vm.$set(_vm.kassaData, "resp_id", $$v)},expression:"kassaData.resp_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.onSubmitProcess.save}},[(_vm.onSubmitProcess.save)?_c('b-spinner',{attrs:{"small":"","type":"grow"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.t('Add'))+" ")],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary","disabled":_vm.onSubmitProcess.save},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.t('Cancel'))+" ")])],1)],1)]}}],null,true)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.onSubmitProcess.open),expression:"onSubmitProcess.open"}]},[_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v(_vm._s(_vm.t('Загрузка'))+"...")])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }