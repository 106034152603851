import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useKassasList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Наименование', sortable: true },
    { key: 'responsible', label: 'Отвественный', sortable: true },
    // { key: 'type', label: 'type', sortable: false },
    { key: 'balance', label: 'Баланс', sortable: true },
    // { key: 'address', label: 'Address', sortable: true },
    // { key: 'discount', label: 'Discount', sortable: false },
    // { key: 'comments', label: 'Comments', sortable: true },
    { key: 'actions' },
    // {
    //   label: 'Responsible',
    //   // formatter: title,
    //   sortable: true,
    // },
  ]

  const queryFields = [
    { label: 'name', whereKeys: ['or.name'], sortKey: 'or.name' },
    { label: 'responsible', whereKeys: ['u0.firstName', 'u0.lastName', 'u0.email'], sortKey: 'u0.firstName' },
    // { label: 'type', whereKeys: ['or.type'], sortKey: 'or.type' },
    { label: 'balance', whereKeys: ['or.balance'], sortKey: 'or.balance' },
    // { label: 'address', whereKeys: ['or.address'], sortKey: 'or.address' },
    // { label: 'comments', whereKeys: ['or.comments'], sortKey: 'or.comments' },
    // { label: 'discount', whereKeys: ['or.discount'], sortKey: 'or.discount' },
    // { label: 'cost', whereKeys: ['or.cost'], sortKey: 'or.cost' },
    // { label: 'quantity', whereKeys: ['or.quantity'], sortKey: 'or.quantity' },
    // { label: 'weight', whereKeys: ['or.weight'], sortKey: 'or.weight' },
    // { label: 'id'whereKeys w[hereKeys: ['or.service'], sortKey: 'or.service' },
    // { label: 'dueDate'whereKeys w[hereKeys: ['or.dueDate'], sortKey: 'or.dueDate' },
    // { label: 'client_id', whereKeys: ['or.client_id'], sortKey: 'or.client_id' },
    // { label: 'wm_id', whereKeys: ['or.wm_id'], sortKey: 'or.wm_id' },
    // { label: 'order_name', whereKeys: ['or.order_name'], sortKey: 'or.order_name' },
    // { label: 'firstname', whereKeys: ['sp.firstname'], sortKey: 'sp.firstname' },
    // { label: 'surname', whereKeys: ['sp.surname'], sortKey: 'sp.surname' },
    // { label: 'phone', whereKeys: ['sp.phone'], sortKey: 'sp.phone' },
    // { label: 'name', whereKeys: ['wm.name'], sortKey: 'wm.name' },
    // { label: 'city', whereKeys: ['wm.city'], sortKey: 'wm.city' },
    // { label: 'address', whereKeys: ['wm.address'], sortKey: 'wm.address' },
    // { label: 'type', whereKeys: ['wm.type'], sortKey: 'wm.type' },
  ]

  const perPage = ref(10)
  const totalKassas = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalKassas.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const q = (val, joiner) => {
    const where = []
    queryFields.forEach(field => {
      field.whereKeys.forEach(column => {
        where.push(`(${column},like,~${val}~)`)
      })
    })

    // eslint-disable-next-line no-plusplus
    // for (let i = 0; i < queryFields.length; i++) {
    //   const columns = queryFields[i].whereKeys
    //   columns.forEach(column => {
    //     where.push(`(${column},like,~${val}~)`)
    //   })
    // }
    return joiner ? `~and(${where.join('~or')})` : where.join('~or')
  }

  const fetchKassas = (ctx, callback) => {
    store
      .dispatch('app-kassa/fetchKassas', {
        _join: 'or.Accounts,_lj,u0.Users',
        _on1: '(or.resp_id,eq,u0.id)',
        _size: perPage.value,
        _p: currentPage.value,
        _sort: (isSortDirDesc.value === true ? '-' : '') + queryFields.filter(item => item.label === sortBy.value)[0].sortKey,
        _where: (statusFilter.value !== null ? `(or.type,eq,${statusFilter.value})` : '') + (searchQuery.value !== '' ? q(searchQuery.value, statusFilter.value !== null) : ''),
        _fields: 'or.id,or.name,or.type,or.balance,or.resp_id,u0.firstName,u0.lastName,u0.email',
        _draw: 1,
        _tableconstruction_version: 'vertri',
        _zeTable: 'Accounts',
      })
      .then(response => {
        const { data, recordsTotal } = response.data

        callback(data)
        totalKassas.value = recordsTotal
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  const deleteKassa = id => {
    store.dispatch('app-kassa/deleteKassa', id)
      .then(response => {
        if (response.data.code === 1) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.',
            },
          })
          // resetForm()
          refetchData()
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              icon: 'XCircleIcon',
              variant: 'error',
              text: 'Нельзя удалить активный аккаунт.',
            },
          })
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          // purchaseData.value = undefined
        }
      })
      .finally(() => {
        // asdad
      })
  }

  return {
    fetchKassas,
    tableColumns,
    perPage,
    currentPage,
    totalKassas,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    deleteKassa,
  }
}
