<template>

  <div>

    <kassas-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :type-options="typeOptions"
      @refetch-data="refetchData"
    />

    <kassas-list-edit
      :id.sync="clientId"
      :is-edit-product-sidebar-active.sync="isEditProductSidebarActive"
      :type-options="typeOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <!-- <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="typeOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ t('Entries') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              v-if="$can('create', 'kassa')"
              variant="primary"
              @click="isAddNewUserSidebarActive = true"
            >
              <span class="text-nowrap">{{ t('Add Account') }}</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <!-- <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                class="invoice-filter-select"
                placeholder="Type"
                :reduce="val => val.value"
                @input="(val) => statusFilter = val"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select> -->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchKassas"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy="!fetchKassas"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              class="align-middle mr-1"
            />
            <strong>{{ t('Загрузка') }}...</strong>
          </div>
        </template>

        <!-- Column: User :to="{ name: 'apps-product-view', params: { id: data.item.id } }" -->
        <template #cell(name)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name }}
          </b-link>
          <!-- <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(`${data.item.firstname} ${data.item.surname}`)"
                :variant="`light-${data.item.type === 'supplier' ? 'success' : 'primary'}`"
                :to="{ name: 'apps-product-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-product-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ `${data.item.firstname} ${data.item.surname}` }}
            </b-link>
            <small class="text-muted">{{ data.item.type }}</small>
          </b-media> -->
        </template>

        <!-- Column: Responsible -->
        <template #cell(responsible)="data">
          <b-media
            v-if="data.item.resp_id"
            vertical-align="center"
          >
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(`${data.item.firstName} ${data.item.lastName}`)"
                :variant="`light-${data.item.type === 'supplier' ? 'success' : 'primary'}`"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ `${data.item.firstName} ${data.item.lastName}` }}
            </span>
            <small class="text-muted">{{ data.item.email }}</small>
          </b-media>
          <small
            v-else
            class="text-muted"
          >No responsible</small>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item :to="{ name: 'apps-product-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item> -->

            <b-dropdown-item
              v-if="$can('edit', 'kassa')"
              @click="editClient(data.item.id)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click.prevent="promptDialog(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalKassas"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
// import UsersListFilters from './UsersListFilters.vue'
// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useKassasList from './useKassasList'
import kassasStoreModule from '../kassasStoreModule'
import KassasListAddNew from './KassasListAddNew.vue'
import KassasListEdit from './KassasListEdit.vue'

export default {
  components: {
    // UsersListFilters,
    KassasListAddNew,
    KassasListEdit,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,

    vSelect,
  },
  methods: {
    promptDialog(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: false,
        })
        .then(value => {
          if (value) {
            this.deleteKassa(id)
          }
        })
    },
  },
  setup() {
    const { t } = useI18nUtils()
    const KASSA_APP_STORE_MODULE_NAME = 'app-kassa'
    const clientId = ref(null)

    // Register module
    if (!store.hasModule(KASSA_APP_STORE_MODULE_NAME)) store.registerModule(KASSA_APP_STORE_MODULE_NAME, kassasStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(KASSA_APP_STORE_MODULE_NAME)) store.unregisterModule(KASSA_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)
    const isEditProductSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const typeOptions = [
      { label: t('Supplier'), value: 'supplier' },
      { label: t('Customer'), value: 'customer' },
    ]

    const statusOptions = [
      { label: 'Supplier', value: 'supplier' },
      { label: 'Customer', value: 'customer' },
    ]

    const {
      fetchKassas,
      tableColumns,
      perPage,
      currentPage,
      totalKassas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      deleteKassa,
    } = useKassasList()

    const editClient = id => {
      clientId.value = id
      isEditProductSidebarActive.value = true
    }

    return {

      // Sidebar
      isAddNewUserSidebarActive,
      isEditProductSidebarActive,
      clientId,
      editClient,

      fetchKassas,
      tableColumns,
      perPage,
      currentPage,
      totalKassas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      typeOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      t,
      deleteKassa,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
